import React from "react";
import PropTypes from "prop-types";

import "./_heading.scss";

const Heading = ({ headingLevel, children, isCentered, headingStyle }) => {
  const Title = headingLevel;
  return (
    <Title
      className={
        isCentered
          ? `heading heading--${
              headingStyle ? headingStyle : headingLevel
            } heading--centered`
          : `heading heading--${headingStyle ? headingStyle : headingLevel}`
      }
    >
      {children}
    </Title>
  );
};

Heading.propTypes = {
  headingLevel: PropTypes.string.isRequired,
  isCentered: PropTypes.bool,
  headingStyle: PropTypes.string,
};

export default Heading;
