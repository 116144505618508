import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import "./_float-gallery.scss";

const FloatGallery = ({ imageData, imageAlt }) => {
  return (
    <div className="float-gallery">
      {imageData.map((image, index) => (
        <div
          key={index}
          className={`float-gallery__wrapper float-gallery__wrapper--${index}`}
        >
          <div className="float-gallery__inner-wrapper">
            <Img fluid={image} alt={imageAlt[index]} />
          </div>
        </div>
      ))}
    </div>
  );
};

FloatGallery.propTypes = {
  imageData: PropTypes.array,
  imageAlt: PropTypes.array,
};

export default FloatGallery;
