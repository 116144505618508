import React from "react";
import PropTypes from "prop-types";

import "./_text.scss";

const Text = ({ children, isCentered }) => (
  <p className={isCentered ? "text text--centered" : "text"}>{children}</p>
);

Text.propTypes = {
  isCentered: PropTypes.bool,
};

export default Text;
