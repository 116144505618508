import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import PropTypes from "prop-types";

import Heading from "components/Heading";
import Text from "components/Text";
import Button from "components/Button";
import { textTransition } from "components/Animations";

const About = ({
  heading,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  fourthParagraph,
  buttonText,
  buttonUrl,
}) => {
  const trigger = useRef(null);
  const headingRef = useRef(null);
  const firstLine = useRef(null);
  const secondLine = useRef(null);
  const thirdLine = useRef(null);
  const fourthLine = useRef(null);
  const button = useRef(null);

  useEffect(() => {
    const tl = new gsap.timeline();
    const { duration, from, to } = textTransition;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          tl.fromTo(headingRef.current, duration, from, to).fromTo(
            firstLine.current,
            duration,
            from,
            to,
            "-=0.7"
          );
          secondLine.current &&
            tl.fromTo(secondLine.current, duration, from, to, "-=0.7");
          thirdLine.current &&
            tl.fromTo(thirdLine.current, duration, from, to, "-=0.7");
          fourthLine.current &&
            tl.fromTo(fourthLine.current, duration, from, to, "-=0.7");
          button.current &&
            tl.fromTo(button.current, duration, from, to, "-=0.7");
          observer.unobserve(trigger.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );
    if (trigger.current) {
      observer.observe(trigger.current);
    }
  }, []);
  return (
    <div ref={trigger}>
      <div ref={headingRef}>
        <Heading headingLevel="h1" isCentered={true}>
          {heading}
        </Heading>
      </div>
      <div ref={firstLine}>
        <Text isCentered={true}>{firstParagraph}</Text>
      </div>
      {secondParagraph && (
        <div ref={secondLine}>
          <Text isCentered={true}>{secondParagraph}</Text>
        </div>
      )}
      {thirdParagraph && (
        <div ref={thirdLine}>
          <Text isCentered={true}>{thirdParagraph}</Text>
        </div>
      )}
      {fourthParagraph && (
        <div ref={fourthLine}>
          <Text isCentered={true}>{fourthParagraph}</Text>
        </div>
      )}
      {buttonText && (
        <div ref={button}>
          <Button url={buttonUrl}>{buttonText}</Button>
        </div>
      )}
    </div>
  );
};

About.propTypes = {
  heading: PropTypes.string,
  firstParagraph: PropTypes.string,
  secondParagraph: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
};

export default About;
